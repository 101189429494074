<template>
    <!-- <v-app-bar v-if="$vuetify.breakpoint.mobile" style="z-index: 998" app> -->
    <v-app-bar v-if="$vuetify.breakpoint.mobile" flat app>
        <v-app-bar-nav-icon v-if="backButton" @click="$emit('input')" />
        <v-btn v-else @click="$router.back()" color="black" icon>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="icon icon-tabler icons-tabler-outline icon-tabler-arrow-narrow-left">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M5 12l14 0" />
                <path d="M5 12l4 4" />
                <path d="M5 12l4 -4" />
            </svg>
        </v-btn>
        <v-toolbar-title class="title">{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('notifications')" icon>
            <v-badge v-if="read" top color="red" dot offset-x="-13" offset-y="-2"></v-badge>
            <v-icon>mdi-bell-outline</v-icon>
        </v-btn>
    </v-app-bar>
</template>

<script>

export default {
    props: ['read'],

    data() {
        return {
            title: this.$route.meta.title || 'Relevent'
        };
    },
    computed: {
        backButton() {
            return this.$route.name !== "MyEvent" && this.$route.name !== "Scan" && this.$route.name !== "ScannerEvent";
        },
    },

    watch: {
        '$route'() {
            this.title = this.$route.meta.title || 'Relevent';
        },
    }
}
</script>

<style lang="css" scoped>
.title {
    font-size: 1.125rem !important;
    font-weight: 700;
}
</style>