<template>
    <v-snackbar v-model="snackbar" :color="data.color">
        <p class="text-subtitle-2 font-weight-semibold mb-0">{{ data.text }}</p>
    </v-snackbar>
</template>

<script>
export default {
    props: ['value', 'data'],

    data() {
        return {
            snackbar: this.value
        };
    },

    watch: {
        value(newVal) {
            this.snackbar = newVal;
        },

        snackbar(newVal) {
            this.$emit('input', newVal);
        },
    }
}
</script>