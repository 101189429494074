<template>
    <!-- @click:outside="$emit('input', false)"  -->
    <v-dialog v-model="value" max-width="400" :transition="false" :fullscreen="isMobile" persistent>
        <v-card>
            <v-card-title class="text-subtite-1 font-weight-semibold">
                Nuevo Colaborador
            </v-card-title>
            <v-divider></v-divider>

            <v-card-text class="pt-4">
                <v-form ref="form" @submit.prevent>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="userData.name" label="Nombre" :rules="[required]" flat outlined
                                hide-details />
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="userData.email" label="Email" type="email" :rules="[required]"
                                hide-details flat outlined />
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="userData.password" label="Password" :rules="[required]" hide-details
                                flat outlined />
                        </v-col>
                        <v-col cols="12">
                            <v-select v-model="userData.role" label="Rol" :items="roles" item-text="text"
                                item-value="value" return-object :rules="[required]" flat outlined hide-details />
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-divider></v-divider>

            <v-card-actions class="px-6 py-4">
                <v-spacer></v-spacer>
                <v-btn class="px-6" @click="close" :disabled="loading" depressed>Cancelar</v-btn>
                <v-btn @click="onSubmit" :loading="loading" :disabled="loading" color="primary" class="px-6"
                    depressed>Crear Colaborador</v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import { createTeam } from '@/api/team'

export default {
    props: ['value'],

    data: () => ({
        loading: false,
        userData: { name: '', email: '', password: '', role: null },
        required: (value) => !!value || "Obligatorio.",
        roles: [
            { text: "Embajador", value: "seller" },
            { text: "Escaner", value: "scan" },
            { text: "Administrador", value: "owner" },
        ],
    }),

    computed: {
        isMobile() {
            return this.$vuetify.breakpoint.mobile;
        },
    },

    methods: {
        async onSubmit() {
            if (!this.$refs.form.validate()) return;
            try {
                this.loading = true;
                const result = await createTeam(this.userData);
                this.$emit('success', result);
            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;
                this.close();
            }
        },

        close() {
            this.$refs.form.reset()
            this.$emit('input', false);
        },
    },
}
</script>