<template>
  <v-container fluid>

    <v-row align="center" justify="space-between" class="mb-4">
      <v-col v-if="!appMobile">
        <h1 class="text-h5 font-weight-bold">Equipo</h1>
      </v-col>
      <v-col cols="12" lg="2">
        <v-btn @click="dialog = true" color="primary" :large="appMobile" depressed block>Nuevo Colaborador</v-btn>
      </v-col>
    </v-row>

    <v-card class="mt-3" outlined>
      <v-list class="py-0">
        <TeamItemList v-for="(item, index) in team" :data="item" :key="index" :last="index < team.length - 1"
          @remove="removeItem($event)" />
      </v-list>
    </v-card>

    <TeamDialogAdd v-model="dialog" @success="addItem($event)" />
    <ToastNoti v-model="snackbar" :data="snackbarContent" />
  </v-container>
</template>

<script>
import { getAllTeam } from "@/api/team";
import TeamItemList from "./TeamItemList.vue";
import TeamDialogAdd from "./TeamAdd.vue";
import ToastNoti from "@/components/Toast.vue";

export default {
  components: { TeamItemList, TeamDialogAdd, ToastNoti },

  data() {
    return {
      team: [],
      dialog: false,
      snackbar: false,
      isLoading: false,
      snackbarContent: { text: "", color: "" },
    };
  },

  methods: {
    async fetchItems() {
      try {
        this.isLoading = true;
        this.team = await getAllTeam();
      } catch (error) {
        console.error("Error: ", error);
      } finally {
        this.isLoading = false;
      }
    },

    addItem(item) {
      this.team.unshift(item);
      this.snackbar = true;
      this.snackbarContent = { text: "Colaborador creado con exito", color: "success" }
    },

    removeItem(userId) {
      const index = this.team.findIndex(user => user.uid === userId);
      if (index !== -1) { this.team.splice(index, 1) }
      this.snackbar = true;
      this.snackbarContent = { text: "Colaborador eliminado con exito", color: "error" }
    },
  },

  mounted() { this.fetchItems() },
};
</script>