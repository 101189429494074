<template>
    <v-navigation-drawer :value="value" @input="$emit('input', $event)" :width="isMobile ? '100%' : '350px'" app right
        floating>
        <template v-slot:prepend>
            <v-toolbar flat>
                <v-toolbar-title class="text-subtitle-1 font-weight-bold">
                    Notificaciones
                    <v-chip class="px-3" small>{{ count }}</v-chip>
                </v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn @click="$emit('close')" icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
        </template>

        <v-list two-line class="pt-0">
            <v-list-item class="notification-item" v-for="(item, index) in items" :key="index">
                <v-list-item-content>
                    <p>
                        <strong class="text--black">
                            {{ item.author }}</strong>,
                        <span class="text--secondary">{{ item.message }}</span>
                    </p>
                    <v-list-item-subtitle class="mt-2">{{ item.date }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import { getNotifications, notificationsAsRead } from '@/api/notifications';

export default {
    props: ['value'],

    data: () => ({
        items: [],
        count: 0
    }),

    watch: {
        async value($event) {
            if ($event) {
                await new Promise(resolve => setTimeout(resolve, 3000));
                await notificationsAsRead(this.items);
                this.$emit('read', false);
            }
        }
    },

    computed: {
        isMobile() {
            return this.$vuetify.breakpoint.mobile;
        },
    },

    async mounted() {
        try {
            const { data, count, read } = await getNotifications();

            this.items = data;
            this.count = count;

            this.$emit('read', read);
        } catch (error) {
            console.error("Error en el componente:", error);
        }
    },
}
</script>

<style lang="css" scoped>
.notification-item {
    font-size: 15px;
    font-weight: 500;
    border-bottom: 1px solid #ecedef;
}
</style>