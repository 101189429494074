import Vue from 'vue'
import VueRouter from 'vue-router'
import { auth } from '@/firebase'


import Panel from '../layouts/Panel.vue'

import LayoutAuth from '../layouts/Auth.vue'

import TeamView from '../views/team/TeamView.vue'


Vue.use(VueRouter)

const authRoutes = [
  {
    path: '/',
    name: 'Login',
    meta: { layout: LayoutAuth },
    component: () => import('../views/AuthLogin.vue')
    // component: () => import('../views/Auth/Login.vue')
  },
  {
    path: '/recuperar-password',
    name: 'Recovery',
    meta: { layout: LayoutAuth },
    component: () => import('../views/Auth/Recovery.vue')
  },
]

const ownerRoutes = [
  {
    path: "/bienvenido",
    name: "owner-home",
    meta: { auth: true, owner: true, layout: Panel, title: 'Eventos' },
    // component: () => import("../views/OwnerHome.vue")
    component: () => import("../modules/events/Home.vue")
  },
  {
    path: "/team",
    name: "TeamView",
    component: TeamView,
    meta: { auth: true, owner: true, layout: Panel, title: 'Equipo' },
  },
  {
    path: '/asistentes/especiales', // Shared
    name: 'attendees-specials',
    meta: { auth: true, owner: true, title: 'Asistentes Especiales', layout: Panel },
    component: () => import('../views/Attendees/VipList.vue')
  },
  {
    path: "/blacklist",
    name: "BlackList",
    meta: { auth: true, owner: true, title: 'Asistentes Bloqueados', layout: Panel },
    component: () => import('../views/Attendees/BlackList.vue')
  },
]

const scanRoutes = [
  {
    path: '/control-puerta/:uid', // Scan
    name: 'Scan', meta: { auth: true, scan: true, layout: Panel, title: 'Control Puerta' },
    component: () => import('../views/scan/ScanView.vue')
  },
  {
    path: '/acceso/:uid',
    name: 'ScannerEvent',
    meta: { auth: true, scan: true, layout: Panel },
    component: () => import("../modules/access/Scanner.vue")
  },
]

const sharedRoute = [
  {
    path: '/version',
    name: 'version',
    meta: { auth: true, seller: true, owner: true, scan: true, layout: Panel, title: 'Información' },
    component: () => import("../views/VersionView.vue")
  },
  {
    path: '/evento/:uid', // Shared
    name: 'MyEvent',
    meta: { auth: true, seller: true, owner: true, scan: true, layout: Panel, title: "Evento" },
    component: () => import("../modules/events/Detail.vue")
  },
  {
    path: '/mis-eventos',
    name: 'MyEvents',
    meta: { auth: true, seller: true, scan: true, layout: Panel, title: 'Eventos' },
    component: () => import("../modules/events/Home.vue")
  },
  {
    path: '/eventos/historial',
    name: 'EventsHistory',
    meta: { auth: true, owner: true, seller: true, layout: Panel,  title: "Eventos Anteriores" },
    component: () => import("../modules/events/History.vue")
  },
]


const routes = [
  ...authRoutes,
  ...scanRoutes,
  ...ownerRoutes,
  ...sharedRoute,
]

const router = new VueRouter({
  mode: 'history', base: process.env.BASE_URL, routes
})

router.beforeEach((to, from, next) => {
  auth.onAuthStateChanged(userAuth => {
    if (userAuth) {
      auth.currentUser.getIdTokenResult().then(function ({ claims }) {
        if (claims.owner) {
          if (to.matched.some(record => record.meta.owner)) {
            next()
          } else {
            return next({ name: 'owner-home' })
          }
        } else if (claims.scan) {
          if (to.matched.some(record => record.meta.scan)) {
            next()
          } else {
            return next({ name: 'MyEvents' })
          }
        } else {
          if (to.matched.some(record => record.meta.seller)) {
            next()
          } else {
            return next({ name: 'MyEvents' })
          }
        }
      })
    } else {
      if (to.matched.some(record => record.meta.auth)) {
        next({ name: 'Login', query: { redirect: to.fullPath } })
      } else {
        next()
      }
    }
  })
  next()
})

export default router