import store from "@/store";
import moment from "moment";
import { db } from '@/firebase';
import { collection, query, where, getDocs, writeBatch, doc } from "firebase/firestore";


export const getNotifications = async () => {
    const companyId = store.state.auth.user.companyId;
    const notificationsRef = collection(db, `companies/${companyId}/notifications`);

    try {

        const lastdate = moment().subtract(30, "days").toDate();

        const q = query(notificationsRef, where("date", ">=", lastdate));
        const querySnapshot = await getDocs(q);

        const notificationsCount = querySnapshot.size

        const notifications = querySnapshot.docs.map(element => {
            const data = element.data();
            data.date = moment(data.date.toDate()).format('dddd DD, MMMM YYYY - HH:mm');
            return { uid: element.id, ...data };
        });

        const read = notifications.some(notification => notification.read === false);

        return { data: notifications, count: notificationsCount, read: read };
    } catch (error) {
        console.error("Error al obtener los mensajes:", error);
        throw error;
    }
}

export const notificationsAsRead = async (array) => {
    const batch = writeBatch(db);
    const companyId = store.state.auth.user.companyId;
    const notificationPath = `companies/${companyId}/notifications`;

    array.forEach(element => {
        if (!element.read) {
            console.debug('Existen nuevas notificaciones')
            const sfRef = doc(db, notificationPath, element.uid);
            batch.update(sfRef, { "read": true });
        }
    });

    try {
        await batch.commit();
        console.log('Notificaciones actualizadas');
    } catch (error) {
        console.error("Error al marcar como leídas las notificaciones:", error);
        throw error;
    }
}