<template>
    <div>
        <v-list-item>
            <!-- avatar -->
            <v-list-item-avatar>
                <v-icon>mdi-account-outline</v-icon>
            </v-list-item-avatar>
            <!-- content -->
            <v-list-item-content>
                <v-list-item-title class="text-subtitle-2 font-weight-semibold">{{ data.displayName
                    }}</v-list-item-title>
                <v-list-item-subtitle class="text-body-2 font-weight-medium">{{ translatedRole }}</v-list-item-subtitle>
            </v-list-item-content>
            <!-- action -->
            <v-list-item-action>
                <v-btn @click="remove(data.uid)" :loading="loading" :disabled="loading" icon>
                    <v-icon color="grey lighten-1">mdi-trash-can-outline</v-icon>
                </v-btn>
            </v-list-item-action>
        </v-list-item>
        <v-divider v-if="last" />
    </div>
</template>

<script>
import { deleteItem } from '@/api/team';

const roleTranslations = {
    seller: 'Embajador',
    owner: 'Administrador',
    scan: 'Puerta'
};

export default {
    props: ['data', 'last'],

    data: () => ({
        loading: false
    }),

    computed: {
        translatedRole() {
            return roleTranslations[this.data.role] || this.data.role;
        }
    },

    methods: {
        async remove(userId) {
            if (confirm('¿Estás seguro de eliminar este usuario?')) {
                try {
                    this.loading = true;
                    await deleteItem(userId);
                    this.$emit('remove', userId);
                } catch (error) {
                    console.error('remove error:', error);
                } finally {
                    this.loading = false;
                }
            }
        }
    }
}
</script>